import createClient, { type Middleware } from 'openapi-fetch'
import type { paths } from '~/src/api/schema'

interface IError {
  '@context': string
  '@type': string
  'hydra:description': string
  'hydra:title': string
}

export const useApi = () => {
  const config = useRuntimeConfig()
  const client = useSupabaseClient()

  const cookie = useCookie('sb-access-token')

  client.auth.onAuthStateChange((event, session) => {
    if (event === 'TOKEN_REFRESHED') {
      cookie.value = session?.access_token
    }
  })

  const headers = computed(() => cookie.value ? { Authorization: `Bearer ${cookie.value}`, 'Content-Type': 'application/ld+json' } : {})

  const apiClient = ref(
    createClient<paths>(
      {
        headers: headers.value,
        baseUrl: config.public.apiEndpoint
      })
  )

  const throwOnError: Middleware = {
    async onResponse ({ response }) {
      if (response.status >= 400) {
        const body: IError = response.headers.get('content-type')?.includes('json')
          ? await response.clone().json()
          : await response.clone().text()
        throw new Error(body['hydra:description'])
      }
      return undefined
    }
  }

  const logoutOnUnauthorized: Middleware = {
    async onResponse ({ response }) {
      if (response.status === 401) {
        await client.auth.signOut()
        navigateTo('/sign-in')
      }
      return undefined
    }
  }

  watchEffect(() => {
    apiClient.value = createClient<paths>(
      {
        headers: headers.value,
        baseUrl: config.public.apiEndpoint
      }
    )
    apiClient.value.use(throwOnError)
    apiClient.value.use(logoutOnUnauthorized)
  })

  return apiClient.value
}
